import React, { Component, useEffect, useState } from 'react';
import {
    Form,
    Input,
    Select,
    Row,
    Col,
    Button,
    message,
} from 'antd';
import {DatePicker, Space} from "antd"
import { InputNumber } from 'antd';
import moment from 'moment';
import Footer from '../../../components/layout-components/modal/Footer';
import { usePosition } from '../../../utils/usePosition';
import API from '../../../api';
import { useWindowDimensions } from '../../../utils/helper';
// var moment = require('moment-timezone');
// const { Option } = Select;

export const UserDetails = [
    {
        name: 'poValue',
        lable: 'PO Value',
        col: 12,
    },
    {
        name: 'expiryDate',
        lable: 'Expiry Date',
        col: 12,
    },
];


const RegistrationForm = (props) => {
    const { updatePurchaseOrder} = API
    // const [data, setState] = useState([]);
    // const dispatch = useDispatch()
    // const loading = useSelector((state) => state?.yardLocationData?.loading);
    // const { latitude, longitude } = usePosition();
    const [form] = Form.useForm();
    const [userDetails, setUserDetails] = useState(UserDetails);
    const [visible, setVisible] = useState(false);
    const [btnDisable, setBtnDisable] = useState(false);
    const { height, width } = useWindowDimensions();
    const [expiry, setExpiry] = useState()
    const dateChangeHandler = (date, dateString)=> {
        setExpiry(date.format('MM/DD/YYYY'))
    }

    useEffect(() => {
        form.setFieldsValue({ poValue: props.data.po_value })
        setExpiry(moment(props?.data?.expiry_date).format('MM/DD/YYYY'))
        // form.setFieldsValue(
        //     { expiryDate: moment(props?.data?.expiry_date).format('MM/DD/YYYY') }
        // )
    }, [])

    const onFinish = (values) => {
        updatePurchaseOrder({
            po_value: parseInt(values.poValue),
            expiry_date: expiry,
            po_id: props?.data?.po_id,
            coordinates: [0, 0]
        }).then((res) => {
            if (res.data.success) {
                message.success(res.data.message)
                props.callPurchaseListApi()
            }
            setVisible(false)
        })
    };

    return (
        <Footer
            fullWidth={width > 100 ? '50%' : false}
            setVisible={(e) => setVisible(e)}
            visible={visible}
            Button={props.Button}
            title={props.title}
            custom={props.custom}
            className={props.className}
            submit={
                <Form.Item>
                    <Button
                        // onClick={onFinish}
                        //   loading={loading}
                        disabled={btnDisable}
                        style={{ background: '#38B6FF', color: '#fff' }}
                        form={props?.data?.po_id ? `editLocation${props?.data?.po_id.toString()}` : 'newLocation'}
                        htmlType="submit"
                    >
                        Submit
                    </Button>
                </Form.Item>
            }
        >
            <Form
                form={form}
                id={props?.data?.po_id ? `editLocation${props?.data?.po_id.toString()}` : 'newLocation'}
                name="register"
                onFinish={onFinish}
                scrollToFirstError
            >
                <Row gutter={24}>
                    {userDetails && userDetails.map((form) => (
                        <Col span={form.col}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                wrapperCol={{ span: 24 }}
                                name={form.name}
                                label={<span>{form.lable}</span>}
                                initialValue={form.initialValue ? form.initialValue : ''}
                                // initialValue={form.initialValue?form.initialValue:''}
                                rules={form.rules}
                            >
                               {form.name === "expiryDate" ? <Space><DatePicker  allowClear={false} value={moment(expiry)} format="MM/DD/yyyy" onChange={(date)=> dateChangeHandler(date)} /></Space> : <InputNumber style={{ width: '100%' }} min={0} /> }
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Footer>
    );
};

export class Register extends Component {
    render() {
        return <RegistrationForm {...this.props} />;
    }
}

export default Register;
